import { parseJwt } from "@/util/parse";

const types = {
  SET_AUTH: "SET_AUTH",
  REMOVE_AUTH: "REMOVE_AUTH",
  UPDATE_PROFIL: "UPDATE_PROFIL"
};

const auth = {
  namespaced: true,
  state: () => ({
    id: 0,
    name: "",
    isAuthenticated: false,
    role: "",
    image: null,
    tokenJWT: "",
    email: ""
  }),
  mutations: {
    [types.SET_AUTH](state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
      state.role = payload.role;
      state.tokenJWT = payload.tokenJWT;
      state.id = payload.id;
    },
    [types.REMOVE_AUTH](state) {
      state.isAuthenticated = false;
      state.role = "";
      state.tokenJWT = "";
      state.name = "";
      state.image = null;
      state.id = 0;
    },
    [types.UPDATE_PROFIL](state, payload) {
      state.email = payload.email;
      state.id = payload.id;
      state.name = payload.name;
      state.image = payload.photo;
    }
  },
  actions: {
    setAuth({ commit }, payload) {
      const { ri, i } = parseJwt(payload);
      console.log(parseJwt(payload));
      commit(types.SET_AUTH, {
        isAuthenticated: true,
        role: ri,
        tokenJWT: payload,
        id: i
      });
    },
    removeAuth({ commit }) {
      commit(types.REMOVE_AUTH);
    },
    updateProfil({ commit }, payload) {
      commit(types.UPDATE_PROFIL, payload);
    }
  },
  getters: {
    getInfoAuth: state => {
      return {
        isAuthenticated: state.isAuthenticated,
        role: state.role,
        name: state.name,
        image: state.image,
        id: state.id,
        email: state.email
      };
    }
  }
};
export default auth;
