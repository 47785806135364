import axios from "axios";
import Vue from "vue";

export const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 80000,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
  // transformResponse: [
  //   function(_response) {
  //     const response = JSON.parse(_response);

  //     if (!response.status) {
  //       throw response.error;
  //     }

  //     return response.data;
  //   }
  // ]
});

api.interceptors.request.use(
  function(config) {
    const token = Vue.prototype.$cookies.get("CERT");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function(_response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("parent");
    return _response.data;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      if (error.response.status === 401) {
        throw {
          msg: "Your session has expired, please login again",
          code: 401
        };
      } else {
        // console.log(error.response.data.error);
        throw error.response.data.error;
      }
    } else {
      throw error;
    }
  }
);
