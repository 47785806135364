<template>
  <div id="app">
    <notifications></notifications>
    <router-view></router-view>
    <SvgSymbols />
  </div>
</template>

<script>
import { api } from "@/services/axios";
import { mapActions } from 'vuex'
import SvgSymbols from '@/components/SvgSymbols'
export default {
  components: {
    SvgSymbols
  },
  methods: {
    ...mapActions('auth', ['removeAuth']),
  },
  mounted() {
    // api.interceptors.response.use(
    //   function (_response) {
    //     // Any status code that lie within the range of 2xx cause this function to trigger
    //     // Do something with response data
    //     return _response.data;
    //   },
    //   (error) => {
    //     // Any status codes that falls outside the range of 2xx cause this function to trigger
    //     // Do something with response error
    //     if (error.response) {
    //       if (error.response.status === 401) {
    //         this.$router.replace({ name: "Login" })
    //         this.$cookies.remove('CERT');
    //         this.removeAuth()
    //         throw "Your session has expired, please login again";
    //       } else {
    //         throw error;
    //       }
    //     } else {
    //       throw error;
    //     }
    //   }
    // );
  }
}
</script>

