const types = {
  SET_SELECTED_STUDENT: "SET_SELECTED_STUDENT"
};

const student = {
  namespaced: true,
  state: () => ({
    selected_student: {
      name: "",
      email: ""
    }
  }),
  mutations: {
    [types.SET_SELECTED_STUDENT](state, payload) {
      state.selected_student.name = payload.name;
      state.selected_student.email = payload.email;
    }
  },
  actions: {
    setSelectedStudent({ commit }, payload) {
      commit(types.SET_SELECTED_STUDENT, payload);
    }
  },
  getters: {
    getInfoSelectedStudent: state => {
      return state.selected_student;
    }
  }
};
export default student;
