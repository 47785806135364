import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";
import { parseJwt } from "@/util/parse";
Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});
// router.beforeEach((to, from, next) => {
// if (to.meta?.requiresAuth && store.state.auth.isAuthenticated) {
//   // let users enter if authenticated
//   if (to.meta?.roles?.includes(store.state.auth.role)) {
//     next();
//   } else {
//     console.log("User is not authorized for route.");
//   }
// } else {
//   // otherwise, route them to /login
//   console.log(store.state.auth);
//   return "/login";
// }
router.beforeEach(async (to, from, next) => {
  let role = null;
  if (Vue.prototype.$cookies.get("CERT")) {
    const { ri } = parseJwt(Vue.prototype.$cookies.get("CERT"));
    role = ri;
    !store.state.auth.isAuthenticated &&
      (await store.dispatch(
        "auth/setAuth",
        Vue.prototype.$cookies.get("CERT")
      ));
  }
  if (to.name !== "Login" && role === null) {
    next({ name: "Login" });
  } else {
    next();
  }
});
// });

export default router;
