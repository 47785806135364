import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/auth";
import student from "@/store/student";
import trainer from "@/store/trainer";
import course from "@/store/course";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { auth, student, trainer, course }
});
