<template>
  <!-- <div class="header" :class="{ [`bg-${type}`]: type }"> -->
  <div class="header" :class="!content ? 'header' : 'header-content'">
    <div class="container-fluid">
      <div class="header-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'base-header',
  props: {
    content: {
      type: Boolean,
      default: false,
      //   description:
      //     'Whether navbar menu is shown (valid for viewports < specified by `expand` prop)'
    },
  }
}
</script>
<style>
</style>
