<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="copyright text-center text-muted">M-eDU Pain @2021</div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {

};
</script>
<style>
</style>
